body {
  background-color: #293132;
}

.main-container {
  padding-left: 1em;
  padding-right: 2em;
  background-color: #78C2AD;
}

.App {
  background-color: #293132;
}

#app-name {
  margin-left: .5em;
  font-weight: bold;
}

.header-section {
  font-family: Trebuchet;
}

.header-wiki, .header-link{
  cursor: pointer;
  color: #293132;
  text-decoration: none;
  margin-left: 1em;
  font-size: 2em;
}

.header-wiki-selected {
  font-weight: bold;
}

#page-tree, #add-new {
  background-color: #98E2CD;
}

#page-tree {
  padding: .5em
}

.tree-page {
  display: block;
  cursor: pointer;
  color: #243;
  font-family: Trebuchet;
}

#content {
  background-color: #F9EAE1;
}

a.header-wiki {
  margin-right: 2em;
}

a.header-wiki,a.tree-page {
  cursor: pointer;
}

h1.page-title {
  padding: 1em 0 0 .5em;
}

.page-edit-form {
  padding: 1em;
}

button.page-gutter {
  height: 3em;
  width: 100%;
  margin-top: 3em;
  background-image: url('./images/plus.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 2em;
}

div.page-gutter:hover {
  cursor: pointer;
}

div.page-section-gutter {
  height: 1.5em;
  border-radius: 1em;
}

.action-button {
  background-color: #ebae7c;
  border: none;
  border-radius: 1em;
}
.action-button:hover {
  background-color: #EEF;
}

div.page-section-gutter.always-visible {
  margin-top:2em;
  background-color: #ebae7c;
  background-image: url('./images/plus.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 1em;
}

div.page-section-gutter:hover {
  background-color: #EEF;
  background-image: url('./images/plus.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 1em;
}

div.page-section-wrapper {
  border-radius: 1em;
  padding: 1em;
}

div.page-section-public:hover {
  background-color: #50D8D7;
}

div.page-section-restricted {
  color: #900;
}

div.page-section-restricted:hover {
  background-color: #F7B7C9;
  color: #000;
}

.secret-text{
  color: #900;
}


div.page-section {
  margin: 1em;
}

div.section-squash {
  margin: 1em;
  padding:1em;
}

.data-entry textarea {
  padding: 1em;
  width: 100%;
}

.section-button {
  width: 100%;
}

.clickable {
  cursor: default;
}

.pencil, .eyeball {
  padding-top: .3em;
}

.react-search-box-dropdown {
  position: absolute;
  top: 5em;
  font-size: .75em;
  width: 40em;
  z-index: 7;
}

@media only screen and (max-width: 760px) {
  .react-search-box-dropdown {
    top: 14em;
  }
}

.gg-icon {
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 22px;
  height: 22px;
  border: 2px solid transparent;
}

.gg-icon::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  width: 10px;
  height: 10px;
  right: 6px;
  top: 4px
}




.gg-chevron-right {
  transform: scale(var(--ggs,1));
}

.gg-chevron-down {
  transform: scale(var(--ggs,1));
}

.gg-chevron-right::after {
  content: "";
  border-bottom: 2px solid;
  border-right: 2px solid;
  transform: rotate(-45deg) scale(0.75, 0.75);
  right: 6px;
  top: 4px
}

.gg-chevron-down::after {
  content: "";
  border-bottom: 2px solid;
  border-right: 2px solid;
  transform: rotate(45deg) scale(0.75, 0.75);
  right: 4px;
  top: 2px
}

.text-bold {
  font-weight: bold
}
